import {ConfigOption} from '@ngx-formly/core';
import {FormlyFieldBoxRadio} from './boxradio/formlyfield.boxradio';
import {FormlyFieldAutocomplete} from './autocomplete/formlyfield.autocomplete';
import {FormlyFieldVerifyPhone} from './verify-phone/formlyfield.verifyphone';
import {FormlyFieldCustomAutocomplete} from './custom-autocomplete/formlyfield.custom-autocomplete';
import {FormlyImageUploadComponent} from './image-upload/image-upload.component';
import {FormlyFieldArrayToggleComponent} from './array-toggle-switch/array-toggle-switch';
import {MatListWrapperComponent} from './wrappers/list-wrapper/list-wrapper';
import {FormlyFieldToggleSwitchComponent} from './toggle-switch/toggle-switch';
import { FormlyFieldReCaptchaComponent } from './re-captcha/re-captcha.component';
import { RepeatTypeComponent } from './repeat-generic/repeat-generic';
import { FormlyFieldSlider } from './slider/fomlyfield.slider.component';
import { FormlyFieldFeedback } from './feedback/fomlyfield.feedback.component';
import { FormlyDynamicTemplateComponent } from './dynamic-template/dynamic-template';
import { FormlyDynamicFieldComponent } from './dynamic-field/dynamic-field';
import { FormlyChipsComponent } from './chips/formly-chips.component';

export const formlyDefaultConfig: ConfigOption = {
  wrappers: [{name: 'mat-list', component: MatListWrapperComponent}],
  validationMessages: [
    {
      name: 'required',
      message: (err, field) => `${field.name || 'Field'} is Required`,
    },
    {
      name: 'pattern',
      message: (err, field) => `${field.name || 'Field'} is not valid`,
    },
    {
      name: 'min',
      message: (err, field) =>
        `${
          field.name || 'Field'
        }'s minmum character requirement is not fullfilled`,
    },
    {
      name: 'max',
      message: (err, field) =>
        `${field.name || 'Field'} exceeds the maximum characters limit`,
    },
  ],
  types: [
    {
      name: 'box-radio',
      component: FormlyFieldBoxRadio,
    },
    {
      name: 'slider',
      component: FormlyFieldSlider,
    },
    {
      name: 'autocomplete',
      component: FormlyFieldAutocomplete,
    },
    {
      name: 'custom-autocomplete',
      component: FormlyFieldCustomAutocomplete,
    },
    {
      name: 'image-upload',
      component: FormlyImageUploadComponent,
    },
    {
      name: 'chips',
      component: FormlyChipsComponent,
      wrappers: ['form-field'],
    },
    {name: 'verify-phone', component: FormlyFieldVerifyPhone},
    {name: 'toggle-switch', component: FormlyFieldToggleSwitchComponent},
    {name: 'array-toggle', component: FormlyFieldArrayToggleComponent},
    {name: 're-captcha', component: FormlyFieldReCaptchaComponent},
    {name: 'repeat', component: RepeatTypeComponent},
    {name: 'feedback', component: FormlyFieldFeedback},
    {name: 'dynamic-template', component: FormlyDynamicTemplateComponent},
    {name: 'dynamic-field', component: FormlyDynamicFieldComponent},
  ],
};
